<template>
  <section>
    <div class="filtered-contents container">
      <div v-for="(item, index) in contents" :key="index">
        <div
          :class="[section.displayType === 'PORTRAIT' ? 'portraitColumn' : (section.displayType === 'LANDSCAPE') ? 'column' : (payload.displayType === 'PORTRAIT') ? 'portraitColumn' : 'smallColumn']"
          v-if="!detailContent"
        >
          <categoryCard
            :content="item"
            :cardType="fetchCardType(item)"
            :id="index"
            @selection="lightUp"
          ></categoryCard>
        </div>
        <div v-if="detailContent">
          <div
            :class="[section.displayType === 'PORTRAIT' ? 'portraitColumn' : (section.displayType === 'LANDSCAPE') ? 'column' : (payload.displayType === 'PORTRAIT') ? 'portraitColumn' : 'smallColumn']"
          >
            <categoryCard
              :content="item"
              :hiddenContent="item"
              :cardType="fetchCardType(item)"
              :id="index"
              @selection="lightUp"
            ></categoryCard>
          </div>
          <div style="margin-bottom:1rem;width:100%;float:left;" v-if="getPosition(item)">
            <detailCard
              :alignment="'grid'"
              :content="detailContent"
              :closePopup="() => toggleDetailCard(false)"
            ></detailCard>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    contents: {
      type: Array
    },
    objectType: {
      type: String
    },
    section: {
      type: [Object, String]
    },
    payload: {
      type: [Object, String]
    }
  },
  data() {
    return {
      toggleDetail: false,
      detailContent: null,
      gridSize: null,
      parentDetailContent: null,
      toggleDetailPopup: null,
      smallDetailContent: null,
      differStyles: null
    };
  },
  watch: {
    parentDetailContent(val) {
      console.log("entered", val);
      this.lightUp(val);
    }
  },
  created() {
    console.log("bold contents", this.contents);
    console.log("section from grid", this.section);
  },
  mounted() {
    eventBus.$on("detail-close", () => {
      // this.detailContent = null;
      this.toggleDetailCard(false);
    });
  },
  beforeDestroy() {
    eventBus.$off("detail-close");
  },
  methods: {
    fetchCardType(data) {
      if (screen.width <= 425) {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "147px",
              width: "261px",
              playButton: { enablePlay: false }
            }
          : this.section.displayType === "PORTRAIT" ||
            this.payload.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "247px",
              width: "165px",
              playButton: { enablePlay: false }
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false }
            };
      } else {
        return this.section.displayType === "LANDSCAPE"
          ? {
              cardName: "Slider",
              type: "LANDSCAPE",
              height: "147px",
              width: "261px",
              playButton: { enablePlay: false }
            }
          : this.section.displayType === "PORTRAIT" ||
            this.payload.displayType === "PORTRAIT"
          ? {
              cardName: "Slider",
              type: "PORTRAIT",
              height: "300px",
              width: "200px",
              playButton: { enablePlay: false }
            }
          : {
              cardName: "Slider",
              type: "SQUARE",
              height: "186px",
              playButton: { enablePlay: false }
            };
      }

      // if (screen.width > 1200) {
      //   return data.objecttype !== "ALBUM" && data.objecttype !== "CHANEL"
      //     ? {
      //         cardName: "Slider",
      //         type: "LANDSCAPE",
      //         height: "147px",
      //         width: "261px",
      //         playButton: { enablePlay: false }
      //       }
      //     : {
      //         cardName: "Slider",
      //         type: "SQUARE",
      //         height: "147px",
      //         width: "147px",
      //         playButton: { enablePlay: false }
      //       };
      // } else if (screen.width >= 768 && screen.width < 1200) {
      //   return data.objecttype !== "ALBUM" && data.objecttype !== "CHANEL"
      //     ? {
      //         cardName: "Slider",
      //         type: "LANDSCAPE",
      //         height: "130px",
      //         width: "235px",
      //         playButton: { enablePlay: false }
      //       }
      //     : {
      //         cardName: "Slider",
      //         type: "SQUARE",
      //         height: "100px",
      //         width: "100px",
      //         playButton: { enablePlay: false }
      //       };
      // } else {
      //   return data.objecttype !== "ALBUM" && data.objecttype !== "CHANEL"
      //     ? {
      //         cardName: "Slider",
      //         type: "LANDSCAPE",
      //         height: "149px",
      //         width: "265px",
      //         margin: "0% auto",
      //         playButton: { enablePlay: false }
      //       }
      //     : {
      //         cardName: "Slider",
      //         type: "SQUARE",
      //         height: "149px",
      //         width: "149px",
      //         margin: "0% auto",
      //         playButton: { enablePlay: false }
      //       };
      // }
    },
    getDetailCard(payload) {
      // this.detailContent = payload.content;
      this.parentDetailContent = payload;
    },
    lightUp(payload) {
      if (screen.width > 900) {
        console.log("content selected", payload);
        eventBus.$emit("off");
        eventBus.$emit(`highlight-${payload.id}`);
        this.detailContent = payload.content;
        document.getElementById("body").style.overflowX = "hidden";
        this.$emit("decreaseMargin");
      } else {
        let contentid = payload.content.objectid;
        this.$router.push({ name: `detail`, params: { contentid } });
      }
    },
    closeDetailPopup(state) {
      this.toggleDetailPopup = state;
    },
    toggleDetailCard(state) {
      document.getElementById("body").style.overflowX = "auto";
      this.detailContent = null;
      this.$emit("increaseMargin");
    },
    getPosition(content) {
      if (this.objectType === "CHANEL" && this.objectType !== "ALBUM") {
        this.gridSize = 6;
      } else if (this.section.displayType === "PORTRAIT") {
        this.gridSize = 5;
      } else {
        this.gridSize = 4;
      }
      let detailIndex =
        this.contents.findIndex(
          el => el.objectid === this.detailContent.objectid
        ) + 1;
      let currentIndex =
        this.contents.findIndex(ele => ele.objectid === content.objectid) + 1;
      let length = this.contents.length;

      let x = detailIndex;
      if (detailIndex % this.gridSize != 0) {
        x = detailIndex + (this.gridSize - (detailIndex % this.gridSize));
      }
      if (x < length + 1) {
        if (currentIndex == x) {
          return true;
        }
      } else if (currentIndex == length) {
        return true;
      }
      return false;
    }
  },
  components: {
    categoryCard: () =>
      import(
        /* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"
      ),
    detailCard: () =>
      import(
        /* webpackChunkName: "detailCard" */ "@/components/Templates/detailCard.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "./gridCategoryCards.scss"
</style>